import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import Imagen5000Esemiautomaticabobina from '../components/imageComponents/imagen5000Esemiautomaticabobina'

const IndexPage = () => (
  <EnLayout>
    <SEO title='ATUOMÁTICA CON BOBINA 5030' />
    <Header siteTitle='OR-PRINTER automatic with 5030 coil' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
          <Imagen5000Esemiautomaticabobina />

        </div>
        <div className='col-12 col-md-6 conmargen'>
          <h3 className={Estilos.username}>Technical specifications:</h3>
          <ul className='aplicaciones'>
            <li> Semi-automatic machine driven by electric motor. </li>
            <li> Motorized tray or pedal work systems. </li>
            <li> Power supply: 230 V. </li>
            <li> Maximum electrical power: 750 W. </li>
            <li> Maximum Pressure: 1300 kgs. </li>
            <li> Maximum piece height: 65 mm. </li>
            <li> Maximum printing area: 200 X 100 mm. </li>
            <li> Work rate of up to 1800 impressions / hour. </li>
            <li>Digital temperature regulator P.I.D. until 300&deg; C. </li>
            <li> Film advance by electric motor controlled by electronic timer. </li>
            <li> Self-tilting head, adjustable in height and inclination. </li>
            <li> Progressive, backward and totalizer digital counter. </li>
            <li> Weight: 69 Kg. </li>
            <li> Dimensions: Height: 75 cms. Width: 90 cms. Depth: 60 cms. </li>
          </ul>

        </div>
      </div>
    </Container>



  </EnLayout>
)

export default IndexPage
